* {
  text-decoration: none !important;
  /* list-style-type: none !important; */
}
a {
  text-decoration: none !important;
}

:root {
  --main-button-color: #3366ff;
}

.text-blue {
  color: #16192c;
}

.rs-picker-menu {
  z-index: 999999 !important;
}

.rs-btn-primary,
.rs-btn-default {
  background-color: var(--main-button-color) !important;
}

/* For dispalying the toasters even above the modals */
.css-1sugtjn {
  z-index: 9999 !important;
}

/* for displaying dialog over a dialog */
.z-idx_20 {
  z-index: 99999999 !important;
}

.sorry-for-this__empty-styles {
  z-index: 999 !important;
}

/* for removing the cross button in the data pickers */
.rs-picker-toggle-clean {
  display: none !important;
}

.rs-btn-toggle-checked {
  background-color: #5fbf47 !important;
}

/* setting width of select picker */
.rs-picker-menu {
  max-width: 900px !important;
}

.rs-steps-item {
  overflow: visible !important;
}

.intake_picker .rs-picker-toggle-clean {
  display: inline-block !important;
}

.error div {
  padding: 2px 3px !important;
  margin-top: 10px !important;
  margin-bottom: -10px !important;
}

.loginForm {
  float: left;
  width: 310px;
  height: 400px;
  margin: auto;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.loginForm img {
  margin-bottom: 30px;
}
.loginForm input {
  position: relative;
  padding: 20px;
  margin-bottom: 10px;
  width: 80%;
}
.loginForm button {
  display: inline;
  margin-bottom: 10px;
}

.NavBar {
  width: 80%;
  margin: auto;
  margin-top: 30px;
}

.container {
  width: 80%;
  margin: auto;
  margin-top: 50px;
}

.formWithProgress {
  margin-left: 250px !important;
}

.NavBar * {
  display: inline-block;
}
.nav {
  position: relative;
  float: right;
}

.rs-navbar-nav > a {
  float: none;
}

.rs-navbar-nav {
  display: flex;
  flex-direction: row;
}

.dropdown-account {
  top: 10px;
  cursor: pointer;
}

.container-header * {
  display: inline-block;
}
.container-header div {
  float: right;
}
.align-left {
  position: relative;
  float: left;
}
.align-right {
  position: relative;
  float: right;
}
.card-default {
  /* position: absolute; */
  margin: auto;
  text-align: center;
  top: 0;
  bottom: 0;
  left: 0;
  padding: 30px;
  right: 0;
  width: 600px;
  height: 420px;
}
.card-default .card-content {
  display: block;
  width: 70%;
  margin: auto;
  margin-bottom: 20px;
}
.RetakeForm {
  width: 80% !important;
}
.RetakeForm input {
  width: 100%;
  margin-bottom: 5px;
}
.SelectCourse {
  margin-bottom: 10px;
}
.SelectCourse button {
  display: inline-block;
}
.SelectCourse .selector {
  width: 77%;
  float: left;
  margin-right: 10px;
}
a {
  text-decoration: none;
  color: inherit;
}
.css-19ryihv {
  display: none !important;
}
.row:nth-child(even) {
  background: #f9f9f9;
}
.ext {
  padding: 20px;
  width: 20px;
}
.container-header span {
  display: inline;
  margin-top: 10px;
  float: right !important;
}
.fileUpload {
  text-align: center;
  border: 1px dashed #298eea;
  padding: 20px;
  padding-bottom: 20px;
  margin-bottom: 35px !important;
  border-radius: 10px;
}
.fileUpload .uploadIcon img,
.familyUpload .uploadIcon img {
  width: 70px;
}
.list-group {
  padding: 0px !important;
}
.list-group li {
  margin-right: 10px !important;
  text-align: center !important;
  display: inline-block !important;
}
.list-group img {
  width: 70px;
}
.currentPage {
  background: #f9f9f9;
}
.pagination {
  list-style-type: none;
  display: inline-block;
  margin: 40px 0px;
  padding: 0px;
}
.pagination li {
  float: left;
  cursor: pointer;
}
.pagination .active {
  background: #298eea;
  color: #fff;
  border: none;
}

.pagination a {
  padding: 6px 12px;
  font-size: 15px;
  border-radius: 4px;
  text-align: center;
  margin-left: 10px;
  background: 1px solid #ccc;
  background-image: linear-gradient(to bottom, #ffffff, #f4f5f7);
  box-shadow: inset 0 0 0 1px rgba(67, 90, 111, 0.14),
    inset 0 -1px 1px 0 rgba(67, 90, 111, 0.06);
  margin-bottom: 10px;
}
.pagination .previous a {
  margin-left: 0px !important;
}
.message {
  position: absolute;
  padding: 16px 0px;
  text-align: center;
  background: #298eea;
  color: #fff;
  width: 100%;
}

/* documents list container */

.document__wrapper {
  margin: 2rem auto;
  padding: 1rem;
}

.document__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 2rem;
  padding: 1rem 0;
}

.doclist__container {
  justify-content: left;
  border: 1px solid #e9e9e9;
  box-shadow: 0px 0px 10px rgb(233, 233, 233);
  flex: 1 0 10%;
  border-radius: 5px;
}

.doclist__container div:first-child {
  border-radius: 5px;
}

.docform__container {
  /* border:1px solid black; */
  align-items: left;
  flex: 1 0 100%;
  margin: 30px 10px;
  margin-left: 5rem;
}

/* file upload area */
.docsubmit__container {
  height: 200px;
  border: 1px dashed rgb(182, 182, 182);
  box-shadow: 0px 0px 2px rgb(224, 224, 224);
  margin: 2rem 0;
}

.docsubmit__container div {
  height: 135px;
  color: grey;
  text-align: center;
  padding: 2rem 0;
  cursor: pointer;
  outline: none;
}

.docsubmit__container div:active {
  border: grey;
}

.inline {
  display: inline;
  z-index: 99;
}

/* document list container */

.viewdocs__container {
  width: 90%;
  margin: 0 auto;
  text-align: center;
}

.heading {
  padding-bottom: 0rem;
}

.inputfield {
  /* margin-bottom: 10px; */
  position: relative;
}

.error {
  color: rgb(240, 9, 9);
  font-size: 0.8rem;

  position: relative;
  margin-top: -1rem;
  top: 0;
  margin-bottom: 1.5rem;
  padding-top: 0;
  transition: 0.5s all ease-in;
}

.move-up {
  margin-right: 8.5%;
  margin-bottom: 2rem;
  position: relative;
  top: 0;
  float: right;
}

/* calender for registration form
 */

.calender {
  padding-bottom: 20px;
  /* border:1px solid rgb(75, 75, 75); */
}

.calender div:first-child {
  border-radius: 4px;
  border: 1px solid rgb(184, 184, 184);
}

/* dropzone container for registration */

.registration__dropzone {
  border: 1px dotted black;
  display: inline;
  padding: 4px 2px;
  border-radius: 4px;
  position: relative;
  box-shadow: 0px 0px 8px rgb(228, 228, 228);
}

.registration__removeDoc {
  background-color: #298eea;
  color: white;
  border-radius: 50%;
  border: none;
  box-shadow: 0px 0px 5px rgb(119, 119, 219);
  cursor: pointer;
  outline: none;
  position: relative;
  left: -20px;
  bottom: 105px;
}

.optionalView {
  position: relative;
  top: 0;
  margin-top: -55px;
  width: 35px;
  height: 30px;
  float: right;
}

/* styles for register page */

.register__label {
  text-align: left;
  display: block;
  width: 250px;
  font-size: 0.85rem;
  font-weight: 500;
  padding-bottom: 4px;
  color: #053252;
}

.register__comment {
  font-size: 0.75rem;
  color: rgb(95, 95, 95);
  font-weight: 500;
  margin-bottom: 4px;
}

.headerSpan span {
  margin-left: 30px;
}

/* calender styles */

.calender.visible {
  position: fixed;
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  position: absolute;
  z-index: 9999999 !important;
}

#__range-picker-container .calendar {
  z-index: 9999999 !important;
}

.full-date-picker-container {
  z-index: 9999999 !important;
}

.user-placeholder {
  width: 50px;
}

.default-placeholder {
  min-width: 50px !important;
  padding: 5px !important;
  border: 1px solid transparent !important;
}

.text {
  display: none;
  width: 100px;
}

.icon {
  cursor: pointer !important;
}

/* retake table */
.retake__tableHeaders input {
  width: 20px !important;
}

.downloadReport span {
  margin-top: 0px !important;
  margin-right: 10px;
}

.rs-uploader button {
  margin-bottom: 6px !important;
}

.familyUpload {
  width: 300px !important;
  text-align: center;
  border: 1px dashed #298eea;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 10px;
}

/* groups message --- inform  1/8/2020 */

.rs-message-container {
  padding: 5px 10px !important;
  font-size: 0.8rem;
  color: rgb(12, 7, 82);
}

/* 📌 */
/* Page not found  📌---- styles */
#notfound {
  position: relative;
  height: 80vh;
}

#notfound .notfound {
  position: absolute;
  left: 55%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.notfound {
  max-width: 560px;
  width: 100%;
  padding-left: 160px;
  line-height: 1.1;
}

.notfound .notfound-404 {
  position: absolute;
  left: 0;
  top: 0;
  display: inline-block;
  width: 140px;
  height: 140px;
  background-size: cover;
  background-image: url("./assets/images/sadFace.png");
}

.notfound .notfound-404:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-transform: scale(2.4);
  -ms-transform: scale(2.4);
  transform: scale(2.4);
  border-radius: 50%;
  background-color: #f2f5f8;
  z-index: -1;
}

.notfound h1 {
  font-family: "Nunito", sans-serif;
  font-size: 65px;
  font-weight: 700;
  margin-top: 0px;
  margin-bottom: 10px;
  color: #151723;
  text-transform: uppercase;
}

.notfound h2 {
  font-family: "Nunito", sans-serif;
  font-size: 21px;
  font-weight: 400;
  margin: 0;
  text-transform: uppercase;
  color: #151723;
}

.notfound p {
  font-family: "Nunito", sans-serif;
  color: #999fa5;
  font-weight: 400;
}

.notfound a {
  font-family: "Nunito", sans-serif;
  display: inline-block;
  font-weight: 700;
  border-radius: 40px;
  text-decoration: none;
  color: #388dbc;
}

/* immigration doc header */
.immigrationDoc__header div {
  padding: 7px;
  margin-bottom: -8px;
  color: #86868b;
  font-size: 0.9rem;
  cursor: pointer;
}

.immigrationDoc__header div::before {
  content: "" !important ;
}

/* timeline for registration page */

.orientation-timeline
  .rs-steps-item-status-process
  .rs-steps-item-icon-wrapper:not(.rs-steps-item-custom-icon) {
  border-color: #007eb7 !important;
  background: #007eb7 !important;
  color: #fff;
}

.orientation-timeline .rs-steps-item-status-process .rs-steps-item-tail {
  /* border-color: #007EB7 !important; */
}

.orientation-timeline
  .rs-steps-item-icon-wrapper:not(.rs-steps-item-custom-icon) {
  border-width: 1px !important;
  border-color: rgb(241, 241, 241) !important;
  background-color: rgb(241, 241, 241) !important;
}

.orientation-timeline .rs-steps-item-icon-wrapper {
  display: block;
  width: 18px !important;
  height: 18px !important;
  top: 3px !important;
}

.orientation-timeline .rs-steps-vertical .rs-steps-item-tail {
  top: 20px !important;
  bottom: 0;
  left: 8.5px !important;
  width: 2px !important;
  border-color: rgb(219, 219, 219);
}

.orientation-timeline .rs-steps-item-icon {
  display: none !important;
}

.orientation-timeline .rs-steps-item-title {
  position: relative;
  font-size: 13px !important;
  left: -11px !important;
  top: -6px !important;
  font-weight: 600;
  color: #18314d !important;
}

.orientation-timeline .rs-steps-item-description {
  position: relative;
  font-size: 12px !important;
  left: -11px !important;
  top: -20px !important;
  color: #343f49 !important;
}

.orientation-timeline
  .rs-steps-item-status-finish
  .rs-steps-item-icon-wrapper:not(.rs-steps-item-custom-icon)
  .rs-steps-item-icon::before {
  color: white !important;
  display: none !important;
}

.orientation-timeline .borderRight {
  border-left: 1px solid rgb(226, 226, 226);
  padding-left: 10px !important;
}

.orientation-timeline .rs-steps-vertical .rs-steps-item:not(:first-child) {
  margin-top: -15px;
}

/* timeline - new and general */
.long-overflow-text {
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.timeline-item-meta {
  color: #8f95b2;
}

.timeline-item-title {
  font-size: 14px;
  color: black;
  padding: 4px 0 4px 0;
  /* prevent long text overflow */
  overflow-wrap: break-word;
  word-wrap: break-word;
}

/* styles for timeline item that has icons */
.withIcons > .rs-timeline-item-custom-dot {
  position: absolute;
  /* a round to hide the line behind icon*/
  background: #fff;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  /* match the size of the icon */
  font-size: 18px;
  margin-left: -5px;
  /* make sure the icon in the circle is centered */
  justify-content: center;
}

.withIcons > .rs-timeline-item-content {
  margin-left: 16px;
  padding-bottom: 24px;
}

.withIcons > .rs-timeline-item-content > .timeline-item-notes {
  background-color: #f4f5f9;
  padding: 5px 10px 5px 10px;
  border-radius: 5px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.scrollGradient {
  background: linear-gradient(#ffffff 33%, rgba(255, 255, 255, 0)),
    linear-gradient(rgba(255, 255, 255, 0), #ffffff 66%) 0 100%,
    radial-gradient(
      farthest-side at 50% 0,
      rgba(143, 138, 138, 0.5),
      rgba(0, 0, 0, 0)
    ),
    radial-gradient(
        farthest-side at 50% 100%,
        rgba(143, 138, 138, 0.5),
        rgba(0, 0, 0, 0)
      )
      0 100%;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-attachment: local, local, scroll, scroll;
  background-size: 100% 72px, 100% 72px, 100% 24px, 100% 24px;
}

/* new accordians   */

.accordian {
  border: 1px solid rgb(218, 226, 229);
  padding: 4px;
  outline: none;
  border-radius: 4px;
  background-color: rgb(237, 239, 242);
  box-shadow: 0px 0px 7px rgb(225, 230, 232);
  cursor: pointer;
}

.collapse {
  display: block !important;
  border: none !important;
  margin-top: 0px !important;
  background-color: #fff !important;
}

.collapse-trigger {
  padding: 0px !important;
  border: none !important;
}

.collapse-trigger:before {
  border: none !important;
  display: none !important;
}

.collapse-panel[aria-expanded="true"] {
  padding: 0px !important;
}

.collapse-panel {
  padding: 0px !important;
}

.side-table {
  width: 200px;
  float: "left";
  margin-right: 30px;
}

.side-table-mobile {
  width: 100%;
}

.registration-form {
  width: 100%;
}

.dashboard-tiles-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
}
.hover-link {
  color: #fff;
  font-size: 18px;
  margin-bottom: 8px;
}
.hover-link:hover {
  color: #5c85ff;
}

.rs-steps-item-title {
  font-weight: 600;
}

/* animation when conditional rendering input field */

@keyframes inputAnimation {
  0% {
    background-color: #f9fafc;
  }
  25% {
    background-color: #ebf0ff;
  }
  50% {
    background-color: #ebf0ff;
  }
  75% {
    background-color: #ebf0ff;
  }
  100% {
    background-color: #f9fafc;
  }
}

.triggerDiv {
  animation: 0.7s 1 ease-out inputAnimation;
}

/* css for headless table ui */

table.table-style {
  border-collapse: separate;
  border-top: 1px solid #e6e8f0;
}

table.table-style > thead > tr > th,
table.table-style > tbody > tr > td {
  border-right: 1px solid #e6e8f0;
  border-bottom: 1px solid #e6e8f0;
  padding: 10px 20px;
}

table.table-style > thead > tr > th {
  background: #f4f6fa;
  text-align: left;
}
/* table.table-style > tbody > tr > td {
  background-color: #ffffff;
} */

/*  only work without background color */
table.table-style > tbody > tr:hover {
  background-color: #f3f6ff;
}

.auto-suggestion-options {
  max-height: 200px;
  overflow-y: scroll !important;
}

/* mobile size */
@media screen and (max-width: 700px) {
  .side-table {
    width: 100%;
  }

  /* container for all the pages */
  .container {
    margin: 5vh 5vw 5vh 5vw;
    width: 90%; /* both margin takes up 10% of the page*/
  }

  .dashboard-tiles-container {
    justify-content: center;
  }
}
