#pageLoading {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  background-color: #f5f5f5;
}

.threeBalls {
  margin: 0 auto;
  width: 70px;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 45%;
}

.threeBalls .ball {
  position: relative;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  -webkit-animation: bouncedelay 2s infinite
    cubic-bezier(0.62, 0.28, 0.23, 0.99) both;
  animation: bouncedelay 2s infinite cubic-bezier(0.62, 0.28, 0.23, 0.99) both;
}

.threeBalls .ball1 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.threeBalls .ball2 {
  -webkit-animation-delay: -0.08s;
  animation-delay: -0.08s;
}

@keyframes bouncedelay {
  0% {
    bottom: 0;
    background-color: #03a9f4;
  }
  16.66% {
    bottom: 40px;
    background-color: #fb6542;
  }
  33.33% {
    bottom: 0px;
    background-color: #fb6542;
  }
  50% {
    bottom: 40px;
    background-color: #ffbb00;
  }
  66.66% {
    bottom: 0px;
    background-color: #ffbb00;
  }
  83.33% {
    bottom: 40px;
    background-color: #03a9f4;
  }
  100% {
    bottom: 0;
    background-color: #03a9f4;
  }
}

@-webkit-keyframes bouncedelay {
  0% {
    bottom: 0;
    background-color: #03a9f4;
  }
  16.66% {
    bottom: 40px;
    background-color: #fb6542;
  }
  33.33% {
    bottom: 0px;
    background-color: #fb6542;
  }
  50% {
    bottom: 40px;
    background-color: #ffbb00;
  }
  66.66% {
    bottom: 0px;
    background-color: #ffbb00;
  }
  83.33% {
    bottom: 40px;
    background-color: #03a9f4;
  }
  100% {
    bottom: 0;
    background-color: #03a9f4;
  }
}
