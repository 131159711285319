/** This is a css file used to make student side view of portal responsive, also since this page is loaded last, change to previous element can be added here as well **/

/********  Mobile Nav Bar ********/
.mobile-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mobile-sidenav {
  width: 100vw;
  height: 100vh;
  z-index: 100;
  position: fixed;
  background-color: #fff;
  margin: 0;
  top: 0;
  left: 0;
}
.no-scroll {
  overflow: hidden;
}

/********  Dashboard, Nav bar  ********/
/* break points
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
*/
.flex-nav ul {
  display: flex;
  flex-wrap: wrap;
}
.dashboard-modal {
  width: auto !important;
  max-width: 600px;
}

@media only screen and (max-width: 992px) {
  .logo-img {
    padding-left: 16px;
  }
  .dashboard-container a:last-of-type .dashboard-pane {
    margin-bottom: 100px;
  }
}

@media only screen and (max-width: 768px) {
  .dashboard-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .dashboard-pane {
    margin-left: 20px;
  }
  .heading-text {
    margin-right: auto;
  }
}

@media only screen and (max-width: 576px) {
  .dashboard-container a:last-of-type .dashboard-pane {
    margin-bottom: 150px;
  }
}

/********  Immigration Documents  ********/

.immigration-container {
  width: 70%;
  margin: 40px auto;
}
.immigration-panel {
  margin-bottom: 20px;
  margin-top: 20px;
}
@media only screen and (max-width: 576px) {
  .immigration-container {
    width: 95%;
  }
  .immigration-doc-col {
    word-wrap: break-word;
  }
  .immigration-panel {
    margin-bottom: 100px;
  }
}

/********  Retake  ********/
/* select box will be styled automatically  */
.retake-cell span {
  white-space: normal !important;
}

.left-cell {
  max-width: 560px;
}
@media only screen and (max-width: 768px) {
  .selector {
    width: 100% !important;
  }
  .responsive-container {
    width: 95% !important;

    margin: auto !important;
    overflow: hidden;
  }
  .view-retake-wrapper {
    margin: 0 !important;
    overflow: hidden;
    width: 100% !important;
  }
  .view-retake-panel {
    margin: 0 !important;
    overflow: hidden;
    width: 90% !important;
  }
  .view-retake-course-head {
    min-width: 0 !important;
    word-break: break-all;
  }
}

/********  Schedules  ********/

@media only screen and (max-width: 768px) {
  .schedule-row {
    height: auto !important;
    flex-direction: column;
    margin-bottom: 20px;
    border: 1px groove #c3c3c3;
  }
  .schedule-cell {
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
    align-items: center;
    justify-content: center;
    border-bottom: 1px groove #c3c3c3;
  }
}

/********  Empty View  ********/
.empty-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 150px;
  margin-left: 300px;
  margin-right: 300px;
}
@media only screen and (max-width: 768px) {
  .empty-container {
    margin-left: 100px;
    margin-right: 100px;
  }
}

@media only screen and (max-width: 576px) {
  .empty-container {
    margin-top: 0px;
    margin-left: 25px;
    margin-right: 25px;
    margin-bottom: 15px;
  }
}

/********  Documents  ********/

@media only screen and (max-width: 768px) {
  .documents-grid {
    width: 90% !important;
  }
}

@media only screen and (max-width: 576px) {
  .documents-grid,
  .documents-row {
    width: 100% !important;
  }
  .documents-cell div {
    white-space: initial !important;
    padding: 0;
    display: flex;
    align-items: center;
    overflow-wrap: break-word;
  }
  .documents-btn-cell div {
    text-overflow: initial !important;
    padding: 0 !important;
    padding-top: 25px !important;
  }
}

/********  Graduation  ********/

@media only screen and (max-width: 768px) {
  .graduation-panel {
    width: 90% !important;
  }
}

/********  Edit Profile  ********/

@media only screen and (max-width: 768px) {
  .edit-profile-panel {
    width: 100% !important;
    margin: 0 !important;
  }
}
/********  Coop  ********/
.addBtn {
  background-color: white !important;
  border: 1px solid #c1c4d6 !important;
  margin-bottom: 10px !important;
}
.addIcon {
  background-color: white !important;
}
.searchBtn {
  width: 150px !important;
  margin-top: 20px !important;
  color: white !important;
}

.selectInput .rs-picker-toggle-clean {
  display: inline-block !important;
}

.organization .rs-table-body-row-wrapper,
.employer .rs-table-body-row-wrapper {
  top: 60px !important;
}
.organization .rs-table-row.rs-table-row-header,
.organization .rs-table-cell-content,
.employer .rs-table-row.rs-table-row-header,
.employer .rs-table-cell-content {
  height: 60px !important;
}
.organization .rs-table-row-header .rs-table-cell,
.employer .rs-table-row-header .rs-table-cell {
  background: #7391c8 !important;
  border: 1px solid #7391c8;
  border-right: 1px solid white;
}
.organization div[data-depth="0"] .rs-table-cell {
  background-color: #e3e7f1;
  border-right: 1px solid white;
}
.rs-calendar-btn-today {
  color: white !important;
}
.calendar-ul {
  padding-inline-start: 1px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dialog {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

/*Danger Button for Rsuite*/

.rs-btn-danger {
  background-color: #e74c3c !important;
  border-color: #e74c3c !important;
  color: white !important;
}
